import * as React from "react";
import { Link } from "react-router-dom";
import DonatePaytm from "../assets/9008881699-paytm.jpeg";

const DonatePage = () => {
  return (
    <div
      className="relative isolate px-6 pt-28 lg:px-8"
      style={{
        height: "100vh",
      }}
    >
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className=""
        style={{
          maxWidth: "1000px",
          width: "100%",
          margin: "0 auto",
          overflow: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
          paddingBottom: "20px",
          height: "100%",
        }}
      >
        <div className="mb-10">
          <h3 className="text-2xl font-bold text-white mb-2">Donate</h3>
          <p className="text-lg text-white mb-4">
            If you like this project, please consider donating to help support
            the development of this project.
          </p>
          <p className="text-lg text-white mb-4">
            {/* <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee"
              className="w-48"
            /> */}
            <img src={DonatePaytm} alt="Donate Paytm" className="w-48" />
          </p>
          {/* increase below the size  */}
          <p className="text-lg text-white mb-4 font-bold text-2xl">
            UPI ID: sanjeevkse@ybl
          </p>

          {/* <p className="text-lg text-white mb-4">
            <a
              href="https://www.buymeacoffee.com/kevinadhiguna"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white underline"
            >
              Buy me a coffee
            </a>
          </p>

          <p className="text-lg text-white mb-4">
            <a
              href="https://www.paypal.com/paypalme/kevinadhiguna"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white underline"
            >
              Donate via PayPal
            </a>
          </p>

          <p className="text-lg text-white mb-4">
            <a
              href="https://trakteer.id/kevinadhiguna"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white underline"
            >
              Donate via Trakteer
            </a>
          </p> */}
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-46rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
    </div>
  );
};

export default DonatePage;
