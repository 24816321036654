import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

import Layout from "../pages/LayoutPage";
import Home from "../pages/LandingPage";
import Product from "../pages/ProductPage";
import About from "../pages/AboutPage";
import Playground from "../pages/PlaygroundPage";
import Donate from "../pages/DonatePage";

const MainRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="playground" element={<Playground />} />
          <Route path="product" element={<Product />} />
          <Route path="about" element={<About />} />
          <Route path="donate" element={<Donate />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </>
  );
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default MainRoute;
