import * as React from "react";

const AboutPage = () => {
  return (
    <div
      className="relative isolate px-6 pt-28 lg:px-8"
      style={{
        height: "100vh",
      }}
    >
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className=""
        style={{
          maxWidth: "1000px",
          width: "100%",
          margin: "0 auto",
          overflow: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
          paddingBottom: "20px",
          height: "100%",
        }}
      >
        <div className="mb-10">
          <h3 className="text-2xl font-bold text-white mb-2">
            About the Product
          </h3>
          <p className="text-lg text-white mb-4">
            UnitRunner is a simple, lightweight and easy-to-use unit test-case
            generator{" "}
            <span
              style={{
                backgroundImage:
                  "linear-gradient(351deg, #31b3ec 23%, #524add 87%, #524add 102%)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              (for now)
            </span>{" "}
            for JavaScript. The playground uses{" "}
            <span
              style={{
                backgroundImage:
                  "linear-gradient(191deg, #f1dc39 25%, #c96e05 87%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              OpenAI
            </span>{" "}
            to generate the test cases. It has the choice of major testing
            frameworks{" "}
            <span
              style={{
                backgroundImage:
                  "linear-gradient(351deg, #31b3ec 23%, #524add 87%, #524add 102%)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              (for now)
            </span>{" "}
            <a href="https://jestjs.io/" target="_blank">
              Jest
            </a>
            ,{" "}
            <a href="https://mochajs.org/" target="_blank">
              Mocha
            </a>
            ,{" "}
            <a href="https://www.chaijs.com/" target="_blank">
              Chai
            </a>{" "}
            and{" "}
            <a href="https://jasmine.github.io/" target="_blank">
              Jasmine
            </a>
            .
          </p>
          <p className="text-lg text-white mb-4">
            The code will be presented to you in the form of a test cases with
            some additional lines to make your life easier.
          </p>
        </div>
        <div className="mb-10">
          <h3 className="text-2xl font-bold text-white mb-2">
            About the Creator
          </h3>
          <p className="text-lg text-white mb-4">Hey Folks,</p>
          <p className="text-lg text-white mb-4">
            I am a full-stack developer (passionate about coding and always
            eager to learn more). I created this product to help developers to
            write unit test cases for their code. I am a big fan of{" "}
            <span
              style={{
                backgroundImage:
                  "linear-gradient(191deg, #f1dc39 25%, #c96e05 87%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              OpenAI
            </span>{" "}
            and{" "}
            <span
              style={{
                backgroundImage:
                  "linear-gradient(191deg, #f1dc39 25%, #c96e05 87%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              React
            </span>
            . I wanted to try out the combination of their API & React.
            Scratching my head lead me to developing this wonderfull
            tool/product (anything) with minimal options for now.
          </p>
          <p className="text-lg text-white mb-4">
            I hope this helps the people.
          </p>
          <p className="text-lg text-white mb-4">
            I hope you like the product. If you have any suggestions or
            feedback, please feel free to reach out to me on{" "}
            <a href="mailto:unitrunner.app@gmail.com" target="_blank">
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(243deg, #3dd875 23%, #0eb3a0 87%)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                unitrunner.app@gmail.com
              </span>
            </a>
          </p>
          <p className="text-lg text-white mb-4">
            You can also find me on{" "}
            <a
              href="https://www.linkedin.com/in/sanjeev-kumar-shetty-e-43865269/"
              target="_blank"
            >
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(243deg, #3dd875 23%, #0eb3a0 87%)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                LinkedIn
              </span>
            </a>
            ,{" "}
            <a href="https://github.com/sanjeevkse" target="_blank">
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(243deg, #3dd875 23%, #0eb3a0 87%)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                GitHub
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
