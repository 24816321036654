import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import CodeEditor from "../components/CodeEditor";
import Select from "../components/Select";
import moment from "moment";
import axios from "axios";
let esprima = require("esprima");

const testingLibraries = [
  {
    value: "jest",
    label: "Jest",
    icon: "https://miro.medium.com/v2/resize:fit:428/format:webp/1*qIG_JF-_UZPgJaycYQ_eiQ.png",
  },
  {
    value: "mocha",
    label: "Mocha",
    icon: "https://miro.medium.com/v2/resize:fit:1058/format:webp/1*YmXmH_1j7h5mp2iK_S2asg.png",
  },
  {
    value: "chai",
    label: "Chai",
    icon: "https://miro.medium.com/v2/resize:fit:560/format:webp/1*9hCO_QRevNSJNFvCKWS7hg.png",
  },
  {
    value: "jasmine",
    label: "Jasmine",
    icon: "https://camo.githubusercontent.com/8ab36dbb00942def1444dc40124df7da6490e8cb5bccf0d8a05ef876a9cd36ad/68747470733a2f2f7261776769746875622e636f6d2f6a61736d696e652f6a61736d696e652f6d61696e2f696d616765732f6a61736d696e652d686f72697a6f6e74616c2e737667",
  },
];

const choices = [
  { value: "ONLY_TEST_CASES", label: "Only Test cases" },
  { value: "ONLY_CODE", label: "Only Code" },
];

const sampleCodeSnippets = {
  multiple2Numbers: {
    title: "Multiply 2 Numbers",
    code: `function multiply(a, b) {
  return a * b;
}`,
  },
  getRandomAlphabet: {
    title: "Get Random Alphabet",
    code: `function getRandomAlphabet() {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  return alphabet[Math.floor(Math.random() * alphabet.length)];
}`,
  },
  isEven: {
    title: "Check if Number is Even",
    code: `function isEven(num) {
  return num % 2 === 0;
}`,
  },
  getMax: {
    title: "Get Max Number from Array",
    code: `function getMax(arr) {
  return Math.max(...arr);
}`,
  },
  average: {
    title: "Get Average of Array",
    code: `function average(arr) {
  return arr.reduce((a, b) => a + b) / arr.length;
}`,
  },
  isPalindrome: {
    title: "Check if String is Palindrome",
    code: `function isPalindrome(str) {
  return str === str.split("").reverse().join("");
}`,
  },
};

const exampleCode = `// Try your code here
`;

const exampleTestCode = `// Unit test cases will appear here`;

export default function PlaygroundPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [testingLibrary, setTestingLibrary] = useState(testingLibraries[0]);
  const [selectedChoice, setSelectedChoice] = useState(choices[0]);
  const [codeEditorContent, setCodeEditorContent] = useState(exampleCode);
  const [generating, setGenerating] = useState(false);
  const [codeHasError, setCodeHasError] = useState(false);
  const [testCodeEditorContent, setTestCodeEditorContent] =
    useState(exampleTestCode);
  const [performance, setPerformance] = useState(0);

  const onClickGenerate = async () => {
    setTestCodeEditorContent("");
    let codeHasValueOtherThenComments = "";
    try {
      codeHasValueOtherThenComments = esprima.parseScript(codeEditorContent);
    } catch (error) {
      alert("Please enter a valid code");
      return false;
    }

    if (codeEditorContent.length > 1000) {
      alert(
        "We are not yet supporting a function more than 900 characters. Please try again with a smaller function. We will keep the platform updated with more features."
      );
      return false;
    }

    try {
      if (codeHasValueOtherThenComments?.body?.length > 0) {
        setGenerating(true);
        const startTime = new Date().getTime();
        const { data, status } = await axios.post(
          "https://lionfish-app-n88mq.ondigitalocean.app/api/v1/ask",
          {
            unitTestLib: testingLibrary.value,
            promptMessage: codeEditorContent,
          }
        );
        // const data = await response.json();
        const timeTaken = new Date().getTime() - startTime;
        setPerformance(timeTaken);
        setTestCodeEditorContent(data.data.data[0].text);
        setGenerating(false);
      } else {
        alert("Please enter a valid & executable code");
        setGenerating(false);
      }
    } catch (error) {
      setGenerating(false);
      console.log(error);
      alert(
        "Facing difficult in generating. Please try again after sometime.!!!"
      );
      return false;
    }
  };

  useEffect(() => {
    try {
      setCodeHasError(false);
      esprima.parseScript(codeEditorContent);
    } catch (error) {
      // console.log("ere", error);
      setCodeHasError(true);
    }
  }, [codeEditorContent]);

  return (
    <div
      className="md:pl-4 md:pr-4"
      style={{
        maxWidth: "1400px",
        margin: "0 auto",
        overflow: "auto",
        // paddingLeft: "20px",
        // paddingRight: "20px",
        paddingTop: "60px",
        paddingBottom: "20px",
      }}
    >
      <div className="bg-gray-700s" style={{ backgroundColor: "#0E1118" }}>
        <div className="bg-indigo-900s text-center py-2 lg:px-4">
          <div
            className="p-2 px-4 bg-indigo-800s items-center text-indigo-100s leading-none lg:rounded-full flex lg:inline-flex"
            role="alert"
            style={{
              backgroundColor: "transparent",
              border: "1px solid #3B82F6",
            }}
          >
            {/* <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
              Note
            </span> */}
            <span className="font-semibold mr-2 text-center flex-auto text-white text-xs">
              The playground supports only vanilla JS functions for now. For
              React, Vue, Angular, NodeJS, etc. our playground provides only the
              test cases without the code. We are working on adding more power.
              Stay tuned! 🚀
            </span>
            <svg
              className="fill-current opacity-75 h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
            </svg>
          </div>
        </div>
        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div className="flex flex-col md:flex-row items-start justify-center">
            <div className="w-full md:w-1/2 flex flex-col flex-1">
              <div className="w-full px-4">
                <div className="mb-3">
                  <div className="flex flex-row items-center flex-wrap">
                    {Object.keys(sampleCodeSnippets).map((key) => (
                      <button
                        key={key}
                        onClick={() =>
                          setCodeEditorContent(sampleCodeSnippets[key]["code"])
                        }
                        className="flex justify-center mr-2 items-center bg-gray-600s border-slate-800 py-2 px-4 rounded-md text-white text-sm hover:bg-gray-500 whitespace-nowrap mb-2"
                        style={{ backgroundColor: "#3C4155" }}
                      >
                        {codeHasError}
                        {sampleCodeSnippets[key]["title"]}
                      </button>
                    ))}
                  </div>
                </div>
                <div>
                  <CodeEditor
                    code={codeEditorContent}
                    onChange={(a) => {
                      setCodeEditorContent(a);
                    }}
                    language={"javascript"}
                    theme={"vs-dark"}
                    readOnly={generating}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 flex flex-col flex-1">
              <div className="w-full px-4">
                <div className="mb-4 flex flex-col md:flex-row items-center">
                  <div className="flex w-full flex-col md:flex-1 md:mr-4">
                    <Select
                      options={testingLibraries}
                      label="Unit Test Library"
                      selected={testingLibrary}
                      setSelected={(a) => {
                        setTestingLibrary(a);
                      }}
                    />
                  </div>
                  {/* <div className="flex flex-col flex-1 mr-4">
                  <Select
                    options={choices}
                    label="Choice"
                    selected={selectedChoice}
                    setSelected={(a) => {
                      setSelectedChoice(a);
                    }}
                  />
                </div> */}

                  <div
                    className="flex w-full flex-col flex-1 md:max-w-min"
                    style={{ maxWidths: "140px" }}
                  >
                    <button
                      className={`mt-2 md:mt-8 group relative flex w-full justify-center rounded-md bg-sky-500s px-3 py-2 text-sm font-semibold text-white hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 pl-4 pr-4`}
                      style={{
                        backgroundColor: "#0052CC",
                        opacity: generating || codeHasError ? 0.7 : 1,
                      }}
                      disabled={generating || codeHasError ? true : false}
                      onClick={() => {
                        onClickGenerate();
                      }}
                    >
                      {/* <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockClosedIcon
                        className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                        aria-hidden="true"
                      />
                    </span> */}
                      {generating && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      {generating
                        ? "Generating"
                        : codeHasError
                        ? "Code has error"
                        : "Generate"}
                    </button>
                  </div>
                </div>
                <CodeEditor
                  code={testCodeEditorContent}
                  language={"javascript"}
                  theme={"vs-dark"}
                  onChange={(a) => {
                    setTestCodeEditorContent(a);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
