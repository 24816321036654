import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";

export default function CodeEditor(props) {
  const { language, onChange, code, theme, readOnly = true } = props;
  const [value, setValue] = useState(code || "");

  const handleEditorChange = (value, event) => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    setValue(code);
  }, [code]);

  return (
    <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">
      <Editor
        height="85vh"
        width={`100%`}
        language={language || "javascript"}
        value={value}
        theme={theme}
        defaultValue=""
        onChange={handleEditorChange}
        options={{
          minimap: {
            enabled: false,
          },
          // fontSize: 16,
          // fontFamily: "JetBrains Mono",
          // lineNumbers: "off",
          // scrollBeyondLastLine: false,
          wordWrap: "on",
          wrappingIndent: "indent",
          // automaticLayout: true,
          folding: false,
          readOnly: readOnly,
          contextmenu: false,
        }}
      />
    </div>
  );
}
